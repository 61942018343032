.dropzone {
    text-align: center;
    padding: 20px;
    border: 2px rgba(0, 0, 0, 0.3) dashed;
    border-radius: 10px;
    width: 60%;
    margin: auto;
}
.btn {
    border: none;
    text-align: center;
    background-color: rgb(218, 216, 216);
    height: 50px;
    border-radius: 12px;
    color: black;
    font-weight: bold;
    transition-duration: 0.6s;
}
.btn:hover {
    background-color: blue;
    color: aliceblue;
}
.file-list {
    /* border: 3px dotted black; */
    display: flex !important;
    flex-wrap: wrap;
    width: auto;
    padding: 10px 20px;
    /*margin: 20px 30px;*/
    /* border: 3px dotted black; */
}

/*.file-item {*/
/*    display: inline-flex;*/
/*    border-radius: 2px;*/
/*    border: 1px solid #eaeaea;*/
/*    margin-bottom: 8px;*/
/*    margin-right: 8px;*/
/*    width: 100px;*/
/*    height: 100px;*/
/*    padding: 4px;*/
/*    box-sizing: border-box*/
/*}*/

.thumbInner {
    display: flex;
    min-width: 0;
    overflow: hidden
}

.file-list img {
    height: 100%;
    padding-right: 10px;
    object-fit: cover;
    display: block;
    width: 100px;
}